export const GET_USER_DATA_FROM_DB = "GET_USER_DATA_FROM_DB";
export const ADD_ROOM = "ADD_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";
export const ADD_GROUP = "ADD_GROUP";
export const DELETE_GROUPS = "DELETE_GROUPS";
export const ADD_MEMBERS = "ADD_MEMBERS";
export const DELETE_MEMBERS = "DELETE_MEMBERS";
export const JOIN_ROOM = "JOIN_ROOM";
export const LEAVE_ROOM = "LEAVE_ROOM";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_SECRET_MESSAGE = "ADD_SECRET_MESSAGE";
export const SAVE_TARGET_GROUP_ID = "SAVE_TARGET_GROUP_ID";
